<template>
  <div class="checkboxWrapper">
    <!-- basic -->
    <CategoryManagerPopUp
      :show-vendors-popup="showVendorsPopup"
      :category-managers-with-vendors="categoryManagersWithVendors"
    />
    <validation-observer ref="userEmailValidation">
      <b-form-group label-for="emailInput">
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <div class="inputAndButtonWrapper">
            <b-form-input
              v-model="userEmail"
              name="email"
              placeholder="Enter Email"
            />
            <b-button
              variant="primary"
              class="getRolesButton ml-1 mr-1"
              @click.prevent="getUserRoles"
            >
              Get Vendors
            </b-button>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <CategoryManagersTable
      :category-managers="categoryManagers"
      @onCopyUser="setUser"
    />
  </div>
</template>

<script>
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormGroup, BButton, BFormInput } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CategoryManagersTable from "./CategoryManagersTable.vue";
import CategoryManagerPopUp from "./CategoryManagerPopUp.vue";

export default {
  components: {
    BFormGroup,
    BButton,
    CategoryManagersTable,
    CategoryManagerPopUp,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  data() {
    return {
      required,
      email,
      userEmail: "",
      userId: null,
      showVendorsPopup: false,
    };
  },
  computed: {
    ...mapState("categoryManagers", [
      "categoryManagers",
      "categoryManagersWithVendors",
    ]),
    toastText() {
      return `You successfully updated ${this.userEmail}'s roles!`;
    },
  },
  created() {
    this.getCategoryManagers();
  },
  methods: {
    ...mapActions("categoryManagers", [
      "getCategoryManagers",
      "getCategoryManagerWithVendors",
    ]),
    setUser(variable) {
      this.userEmail = variable.email;
      this.userId = variable.id;
      this.getUserRoles();
    },
    getUserRoles() {
      if (this.userEmail) {
        this.showVendorsPopup = true;
        this.getCategoryManagerWithVendors({ id: this.userId });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Choose user to get vendors",
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
    },
    validationForm() {
      this.$refs.userEmailValidation.validate().then((success) => {
        if (success) {
          // this.setUserRole({
          //   email: this.userEmail,
          //   roles: this.userRoles.map((x) => ({
          //     id: x.roleId,
          //     isEnabled: this.selected.includes(x.value),
          //   })),
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.toastText,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.checkboxWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputAndButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;

  @media (max-width: 600px) {
    flex-direction: column;
    height: unset;
  }
}

.getRolesButton {
  width: 20%;

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 15px;
  }
}

.inputAndButtonWrapper input {
  width: 70%;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }

  height: 100%;
}
</style>
