<template>
  <b-modal
    id="modal-xl"
    v-model="showVendorsPopup"
    size="xl"
    centered
    @ok="addVendor"
  >
    <h1 class="d-flex justify-content-center mb-2">Vendors</h1>
    <VendorsTable
      :data-vendors="categoryManagersWithVendors"
      @vendorData="getVendors"
    />
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import VendorsTable from "./VendorsTable.vue";

export default {
  components: {
    VendorsTable,
  },

  props: {
    showVendorsPopup: Boolean,
    categoryManagersWithVendors: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedVendors: [],
      vendorId: null,
    };
  },
  methods: {
    ...mapActions("categoryManagers", ["setCategoryRoles"]),
    getVendors(value) {
      this.selectedVendors = value.vendors;
      this.vendorId = value.id;
    },
    addVendor() {
      if (this.selectedVendors.length) {
        this.setCategoryRoles({
          vendors: this.selectedVendors,
          id: this.vendorId,
        });
      }
    },
  },
};
</script>
