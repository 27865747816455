<template>
  <div>
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col>
          <b-form-group>
            <label>Search:</label>
            <b-form-input
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span>{{ props.row.fullName }}</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { mapActions } from "vuex";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    dataVendors: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      selectedVendors: {
        vendors: [],
        id: null,
      },
      columns: [
        {
          label: "ID",
          field: "vendor.id",
        },
        {
          label: "Brand Name",
          field: "vendor.brandName",
        },
        {
          label: "Email",
          field: "vendor.email",
        },
        {
          label: "Mobile",
          field: "vendor.mobile",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  created() {
    this.rows = this.dataVendors.data.vendors;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.rows.length; i++) {
      this.$set(this.rows[i], "vgtSelected", this.rows[i].isAssigned);
    }
  },
  methods: {
    ...mapActions("categoryManagers", ["setCategoryRoles"]),
    advanceSearch(val) {
      this.searchTerm = val;
    },
    onRowClick(params) {
      this.selectedVendors.vendors.push(params.row.vendor.id);
      this.selectedVendors.id = params.row.categotyManagerId;
      this.$emit("vendorData", this.selectedVendors);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `You've added vendor with ID: ${params.row.vendor.id}`,
          icon: "UserIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>
